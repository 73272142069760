














































































































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import { CustomerPaymentCreateDialogProperties } from "./CustomerPaymentCreateDialogView.vue";
import { CustomerPaymentCustomerProperties } from "./CustomerPaymentCustomerView.vue";
import { DateFilterDialogProperties } from '@/components/shared/DateFilterDialogView.vue';
import ListViewMixin from '@/components/shared/ListViewMixin';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import RouteUtil from '@/utilities/RouteUtil';
import StringUtil from '@/utilities/StringUtil';
import DateUtil from '@/utilities/DateUtil';
import ResponseUtil from '@/utilities/ResponseUtil';
import FilterUtil from '@/utilities/FilterUtil';
import CustomerPaymentService from '@/services/CustomerPaymentService';

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const CustomerPaymentCreateDialogView = () => import("./CustomerPaymentCreateDialogView.vue");
const CustomerPaymentCustomerView = () => import("./CustomerPaymentCustomerView.vue");
const DateFilterDialogView = () => import("@/components/shared/DateFilterDialogView.vue");

@Component({
    components: { 
        AlertDialogView, 
        CustomerPaymentCreateDialogView,
        CustomerPaymentCustomerView,
        DateFilterDialogView, 
    },
    data: () => ({        
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            paymentNo: "",
            paymentDateFrom: null,
            paymentDateTo: null,
            customerCode: "",
            customerName: "",
			pageScopedId: "",
            type: "All",
            status: "All"
        },
        customerPayments: []
    })
})
export default class CustomerPaymentListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private dateFilterDialog = new DateFilterDialogProperties();
    private customerPaymentCreateDialog = new CustomerPaymentCreateDialogProperties();
    private customerPaymentCustomer = new CustomerPaymentCustomerProperties();
    private customerPaymentService = new CustomerPaymentService();
    private dateTimeFormat: string = null;
    private dateFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.payment-no"),
                value: "uniqueNo",
                width: "9%",
            },
            {
                text: this.$t("text.payment-date"),
                value: "formatted.paymentDate",
                width: "170px"
            },
            {
                text: this.$t("text.customer-code"),
                value: "customerCode",
                width: "9%"
            },
            {
                text: this.$t("text.customer-name"),
                value: "formatted.name"
            },
			{
				text: this.$t('text.psid'),
				value: "customer.facebookAccount.pageScopedId",
				width: "9%"
			},
            {
                text: this.$t("text.type"),
                value: "formatted.type",
                width: "135px"
            },
            {
                text: this.$t("text.status"),
                value: "formatted.status",
                width: "150px"
            },
            {
                text: this.$t("text.total-payment"),
                value: "formatted.totalPayment",
                align: "right",
				width: "7%"
            },
            {
                text: this.$t("text.total-amount"),
                value: "formatted.totalAmount",
                align: "right",
				width: "7%"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public typeOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.deposit'),
                value: "Deposit",
                all: false
            },
            {
                text: this.$t('text.checkout'),
                value: "Checkout",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public statusOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.draft'),
                value: "Draft",
                all: false
            },
            {
                text: this.$t('text.processing'),
                value: "Processing",
                all: false
            },
            {
                text: this.$t('text.approved'),
                value: "Approved",
                all: false
            },
            {
                text: this.$t('text.cancelled'),
                value: "Cancelled",
                all: false
            },
            {
                text: this.$t('text.refunded'),
                value: "Refunded",
                all: false
            },
            {
                text: this.$t('text.rejected'),
                value: "Rejected",
                all: false
            },
            {
                text: this.$t('text.delivered'),
                value: "Delivered",
                all: false
            },
            {
                text: this.$t('text.completed'),
                value: "Completed",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public isDraft(item: any) {
        return item.status === "Draft";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatType(v: any) {
        return this.typeOptions().find(x => x.value === v).text;
    }

    public formatStatus(v: any) {
        return this.statusOptions().find(x => x.value === v).text;
    }

	public customerUrl(item: any) {
		return `/management/customers/modify?id=${item.customer.id}`;
	}

    public created() {
        this.customerPaymentCreateDialog.events.subscribe('created', this.createdRecord);
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt(query.pageNo as any ?? 1);
        data.table.recordCount = parseInt(query.recordCount as any ?? 10);

        data.filter.paymentNo = query.paymentNo ?? "";
        data.filter.paymentDateFrom = FilterUtil.dateOf(query.paymentDateFrom);
        data.filter.paymentDateTo = FilterUtil.dateOf(query.paymentDateTo);
        data.filter.customerCode = query.customerCode ?? "";
        data.filter.customerCode = query.customerName ?? "";
		data.filter.pageScopedId = query.psid ?? "";
        data.filter.type = query.type ?? "All";
        data.filter.status = query.status ?? "All";

        this.load();
    }

    public destroyed() {
        this.customerPaymentCreateDialog.events.remove('created', this.createdRecord);
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const type = filter.type === 'All' ? null : filter.type;
            const status = filter.status === 'All' ? null : filter.status;

            const r = await this.customerPaymentService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                uniqueNo: StringUtil.enclose(filter.paymentNo, "%", "%"),
                paymentDate: FilterUtil.filterDate(false, filter.paymentDateFrom, filter.paymentDateTo),
                customerCode: StringUtil.enclose(filter.customerCode, "%", "%"),
				pageScopedId: StringUtil.enclose(filter.pageScopedId, "%", "%"),
                type,
                status,
                searchCustomer: filter.customerName,
                loadCustomer: true,
				loadFacebookAccount: true
            });
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.dateFormat = ResponseUtil.getDateFormat(r);

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            var payments: any[] = r.data.customerPayments;

			payments.forEach(e => {
				var v = e.receiptPayment ?? 0;
                v += e.walletPayment ?? 0;
				v += e.cashPayment ?? 0;
				e.totalPayment = v;
				e.totalWeight ??= 0;

				const c = e.customer;
				const f = c.facebookAccount;
				const n = c.name;
				const u = f.uniqueNo ?? null;

				e.formatted = {
					paymentDate: this.formatDate(e.paymentDate),
					name: u !== null ? `${n} [${u}]` : n,
					type: this.formatType(e.type),
					status: this.formatStatus(e.status),
					totalPayment: e.totalPayment.toFixed(2),
                    totalAmount: e.totalAmount.toFixed(2),
					totalWeight: e.totalWeight.toFixed(2)
				};
            });

            this.$data.customerPayments = payments.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/customer/customer-payments", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                uniqueNo: filter.paymentNo,
                paymentDateFrom: filter.paymentDateFrom,
                paymentDateTo: filter.paymentDateTo,
                customerCode: filter.customerCode,
                customerName: filter.customerName,
				psid: filter.pageScopedId,
                type,
                status
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async remove(record: any) {
         try {
            await this.customerPaymentService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }


    public create() {
        this.customerPaymentCreateDialog.visible = true;
    }

    public async createdRecord(record: any) {
        await this.$router.push("/management/customer/customer-payments/modify?id=" + record.id);
    }
    
    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.uniqueNo);
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public filterPaymentDate() {
        const filter = this.$data.filter;
        this.dateFilterDialog.title = this.$t('text.payment-date').toString();
        this.dateFilterDialog.dateFrom = filter.paymentDateFrom;
        this.dateFilterDialog.dateTo = filter.paymentDateTo;
        this.dateFilterDialog.labelDateFrom = this.$t('text.date-from').toString();
        this.dateFilterDialog.labelDateTo = this.$t('text.date-to').toString();
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.tag = 'payment-date';
        this.dateFilterDialog.visible = true;
    }

    public async filteredDate() {
        const filter = this.$data.filter;
        if (this.dateFilterDialog.tag === 'payment-date') {
            filter.paymentDateFrom = this.dateFilterDialog.dateFrom;
            filter.paymentDateTo = this.dateFilterDialog.dateTo;
            await this.load();
        }
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === 'remove' && button === this.$t('text.yes')) {
                this.remove(tag.record);
            }
        }
    }
}
